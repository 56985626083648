<div class="table-wrapper ccu-logs-table-wrapper" id="ccu-logs-table-wrapper">
    <div class="main-header">
        <div class="ccu-logs-header-items">
            <div class="logs-header-item">
                <app-select-dropdown [formFieldClass]="'ccuDropdownFormField'"
                                [placeholderLabel]="'Select CCU'" [(ngModel)]="selectedCCUId"
                                [data]="ccuList" [displayKey]="'formattedProfile'" [valueKey]="'ccuId'">
                </app-select-dropdown>
            </div>

            <div class="logs-header-item">
                <app-select-dropdown [formFieldClass]="'sequenceDropdownFormField'"
                                [placeholderLabel]="'Select Sequence'" [(ngModel)]="selectedSequenceId"
                                [data]="sequenceList" [displayKey]="'seqName'" [valueKey]="'seqId'">
                </app-select-dropdown>
            </div>

            <div class="logs-header-item action-btn-container">
                <button mat-button class="action-btn header-button" color="primary" id="request_ccu_logs-btn"
                    (click)="requestCCULogs()" [disabled]="!(this.selectedSequenceId && this.selectedSequenceId)">
                    REQUEST LOGS
                </button>
            </div>
        </div>

        <div class="header-icons-container" [ngClass]="{'ml-auto': isLoading}">
            <div class="close-button-container">
                <button mat-icon-button mat-dialog-close class="close-button" id="ccu-logs-modal-close-button">
                    <i class="fas fa-times"></i>
                </button>
            </div>
        </div>

    </div>

    <div class="table-container"
        [ngClass]="{'loader-active': isLoading}">
        <table mat-table [dataSource]="dataSource" [fixedLayout]="true" matSort style="width: 100%" id="ccu-logs-table">
            <ng-container *ngFor="let column of headerColumnNames" [matColumnDef]="column" sticky>
                <th mat-header-cell *matHeaderCellDef mat-sort-header 
                    [ngClass]="column === 'requestTime' ? 'width-90' : 'width-10'"
                >{{ getFormattedColumnName(column) }}</th>
                <td mat-cell *matCellDef="let row" [ngClass]="column === 'requestTime' ? 'width-90' : 'width-10'">
                    <ng-container [ngSwitch]="column">
                        <ng-container *ngSwitchCase="'requestTime'">
                            <p class="data-value" matTooltip="" matTooltipPosition='above' showTooltipOnOverflow>
                                {{ row.requestTime ? (row.requestTime | date:'MMMM d, y | hh:mm a') : '-' }}
                            </p>
                        </ng-container>

                        <ng-container *ngSwitchCase="'action'">
                            <button mat-icon-button class="logs-download-button" id="ccu-logs-download-button"
                                    (click)="handleDownload(row.fileId)" *ngIf="row.fileId">
                                <i class="fas fa-download" id="ccu-logs-download-icon"></i>
                            </button>
                        </ng-container>

                    </ng-container>
                </td>
            </ng-container>

            <!-- Header and Row definitions -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <div class="placeholder-text" id="placeholder-text-container" *ngIf="placeholderMessage && !isLoading">
            <div class="message-text" id="placeholder-message-text">{{placeholderMessage}}</div>
        </div>
    </div>

    <div class="loader-wrapper" id="loader-wrapper" *ngIf="isLoading">
        <div class="row">
          <div class="loader-column" id="loader-column" *ngFor="let repeat of [0,1,2,3]">
            <ngx-skeleton-loader [theme]="{ 'border-radius': '0', 'height': '30px', 'width':'100%', 'background-color': '#eff0f1'}"
              count="3">
            </ngx-skeleton-loader>
          </div>
        </div>
    </div>

</div>