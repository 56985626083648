<div mat-dialog-content>
  <div class="row align-items-center">
    <div class="p-l-0 pre-wrap">

      <div class="scrollable-content" [ngClass]="{'isFixedHeight': isFixedHeight}" *ngIf="scopeData.length > 0">
        <div class="search-container scope-list-search">
          <mat-form-field class="search-input">
            <input matInput placeholder="Search for System" #searchTextField (keyup)="applyFilter(searchTextField?.value)" autocomplete="off" />
            <i class="fas fa-search" matPrefix aria-hidden="true"></i>
          </mat-form-field>
        </div>
        <div class="data-container">
          <ul class="data-list">
            <li class="data-list-item select-all" *ngIf="!isFilteringData">
              <mat-checkbox [checked]="allSystemsSelected()" 
                [indeterminate]="someSystemsSelected()"
                (change)="toggleSelectAll($event.checked)">
                All Systems
              </mat-checkbox>
            </li>
            <ng-container *ngIf="anyFilteredSystems(); else noResults">
              <ng-container *ngFor="let system of scopeData">
                <li *ngIf="!isFilteringData || system.filtered" class="data-list-item">
                  <mat-checkbox [(ngModel)]="system.selected" (change)="updateAllComplete(system)">
                    {{ system.ccuName }} - {{ system.formattedProfile }}
                  </mat-checkbox>
                </li>
              </ng-container>
            </ng-container>
            <ng-template #noResults>
              <li class="data-list-item placeholder-text">
                <div>No Results Found</div>
              </li>
            </ng-template>
          </ul>
        </div>
      </div>

      <div class="scrollable-content" [ngClass]="{'isFixedHeight': isFixedHeight}" *ngIf="scopeData.length <= 0">
        <h4>No Data Found</h4>
      </div>

    </div>
  </div>
</div>